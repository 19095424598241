export enum SectionsType {
  Relationships = 'relationships',
  SubmissionForm = 'submission-form',
  Upload = 'upload',
  License = 'license',
  CcLicense = 'cclicense',
  collection = 'collection',
  CustomUrl = 'custom-url',
  AccessesCondition = 'accessCondition',
  DetectDuplicate = 'detect-duplicate',
  ReserveDoi = 'reserve-doi',
  Correction = 'correction',
  FHGLicense = 'fhgLicense'
}
