import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { LocaleService } from "src/app/core/locale/locale.service";

@Injectable({
    providedIn: 'root'
})
export class ImprintResolver implements Resolve<string> {

    generator_url: string = "https://dsi.informationssicherheit.fraunhofer.de/de/impressum/impressum-4";
    generator_url_en: string = "https://dsi.informationssicherheit.fraunhofer.de/en/impressum/impressum";

    imprint: string = "";

    constructor(protected localeService : LocaleService)
        {}

    
    resolve(): Promise<string> {
        var promise=this.getImprint();
        return promise.then((res :string) => {
            return res;
        });
    }

    private getImprint() : Promise<string> {
        var url=""
        if(this.localeService.getCurrentLanguageCode() == "de") {
            url=this.generator_url;
        } else {
            url=this.generator_url_en;
        }
        return fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'omit'})
        .then(function(response) {
            if (!response.ok) {
                return(response.statusText);
            }
            return response.text()})
    }
    
}